import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  ContainerCard,
  ContainerList,
  CardTitle,
  ListTitle,
  ListMarker,
  ListRoot,
  ListItem,
  StyledImg,
  CardDesc,
  PhotoContainer,
  QuoteContainer,
  QuoteText,
} from './spotlight.css';

const Spotlight = ({ image, quote }) => {
  return (
    <Row>
      <ContainerCard>
        <PhotoContainer>
          <StyledImg fluid={image ? image.childImageSharp.fluid : {}} alt="Dott.ssa Lara Bartali"></StyledImg>
        </PhotoContainer>
        <QuoteContainer>
          <CardTitle>Dott.ssa Lara Bartali</CardTitle>
          <QuoteText>
            {quote}
          </QuoteText>
        </QuoteContainer>
      </ContainerCard>
      <ContainerList>
        <ListTitle>Di cosa mi occupo?</ListTitle>
        <ListRoot>
          <ListItem><ListMarker />Tunnel carpale</ListItem>
          <ListItem><ListMarker />Dito a scatto</ListItem>
          <ListItem><ListMarker />Esercizi pre operatori lca o menisco</ListItem>
          <ListItem><ListMarker />Cuffia rotatori</ListItem>
          <ListItem><ListMarker />Esercizi pre-operatori spalla</ListItem>
          <ListItem><ListMarker />Esercizi tutore</ListItem>
          <ListItem><ListMarker />Cicatrici</ListItem>
          <ListItem><ListMarker />Fascite plantare</ListItem>
          <ListItem><ListMarker />Pavimento pelvico</ListItem>
          <ListItem><ListMarker />Artrosi</ListItem>
        </ListRoot>
      </ContainerList>
    </Row>
  );
};

Spotlight.propTypes = {
  image: PropTypes.object.isRequired,
  quote: PropTypes.string.isRequired,
};

export default Spotlight;

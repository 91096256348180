import React from 'react';
import PropTypes from 'prop-types';
import { Title, Copy, StyledImg, StyledFigCaption, StyledFigure } from './item.css';

const Item = ({ title, copy, image }) => (
  <StyledFigure>
    <StyledImg fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
    <StyledFigCaption>
      <Title>{title}</Title>
      <Copy>{copy}</Copy>
    </StyledFigCaption>
  </StyledFigure>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  ContainerTitle,
  Title,
  StyledBackgroundSection,
  StyledImg,
  StyledButtonsHolder,
  StyledButton,
  StyledButtonLink,
} from './landing.css';

const Landing = ({ message, image, logo, telephone }) => {
  return (
    <Row>
      <StyledBackgroundSection className="landing-background" image={image}>
        <ContainerTitle>
          <StyledImg
            fluid={logo ? logo.childImageSharp.fluid : {}}
            alt={message}
          />
          <Title as="h1" size="large">
            {message}
          </Title>
          <StyledButtonsHolder>
            <StyledButton href={telephone ? telephone : 'tel:00393393346415'}>
              Richiedi un colloquio via email
            </StyledButton>
            <StyledButton href="https://wa.me/393393346415">
              Scrivimi su WhatsApp
            </StyledButton>
          </StyledButtonsHolder>
        </ContainerTitle>
      </StyledBackgroundSection>
    </Row>
  );
};

Landing.propTypes = {
  message: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  telephone: PropTypes.string,
};

export default Landing;

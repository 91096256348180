import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import BackgroundSection from 'components/home/spotlight/background';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  /* width: 100vw; */
  padding: 0 10vw;
  margin-top: 25vh;

  background-color: #222;
  color: #fff;

  ${MEDIA.DESKTOP`
    flex-direction: column;
    min-height: 100vh;
  `};
`;

export const ContainerCard = styled.div`
  flex: 0 0 auto;
  padding-left: 5vw;
  padding-right: 5vw;
  min-height: 67.5vh;
  position: relative;
  top: -20vh;
  margin-right: 2vw;
  background-color: #e3e3e3;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerList = styled.div`
  flex: 1 0 auto;
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${MEDIA.TABLET`
    margin-top: -20vh;
  `};
`;

export const CardTitle = styled.h2`
  color: #1a486a;
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

export const CardDesc = styled.p`
  color: #000;
  margin-top: 1rem;
`;

export const ListRoot = styled.ul`
  list-style-type: none;
`;

export const ListItem = styled.li`
  margin: 1rem 0;
  padding: 1rem 0;
  ::before {
    content: '-':
    margin-left: -2rem;
  }
`;


export const ListTitle = styled.h2`
  color: #3895db;
  font-size: 3rem;
  margin: 1rem 0;
  padding: 1rem 0;
`;

export const Figure = styled.figure`
  width: 100vw;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100%;
  ${MEDIA.DESKTOP`
    width: 100vw;
  `};
`;

export const StyledImg = styled(Img)`
  display: block;
  width: 15vw;
  margin-bottom: 1rem;
  ${MEDIA.DESKTOP`
    width: 33vw;
  `};
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  justify-content: center;
  align-items: center;
`;

export const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;

export const QuoteText = styled.h3`
  font-style: italic;
  color: #1a486a;
  text-align: right;
`;

export const ListMarker = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 2rem;
  background-color: #3895db;
  border-radius: 50%;
  display: inline-block;
`;

import styled from 'styled-components';
import PageTitle from 'components/page-title';
import MEDIA from 'helpers/mediaTemplates';

export const StyledPageTitle = styled.span`
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: block;
  font-size: 5rem;
  color: #1a5d8f;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: stretch;
  text-align: center;
  padding: 4rem 15vw;
  margin: 2rem;
  ${MEDIA.TABLET`
    flex-direction: column;
    padding: 0;
  `};
`;

import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/home/gallery/item';
import { Container, StyledPageTitle } from './gallery.css';

const Gallery = ({ items }) => (
  <>
    <StyledPageTitle>Cosa Propongo</StyledPageTitle>
    <Container>
      {items.map((item, i) => (
        <Item {...item} key={i} />
      ))}
    </Container>
  </>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery;
